<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="订单编号" prop="order_no"></el-table-column>
          <el-table-column
            label="订单价格"
            prop="order_price"
            align="right"
            width="150"
          ></el-table-column>
          <el-table-column label="工程师" align="center" width="120">
            <template #default="{ row }">
              <!-- <el-button type="text" :icon="Edit">
                <template v-for="item in row.engineer">
                  {{ item.name }}
                </template>
              </el-button> -->
              <template v-for="item in row.engineer">
                {{ item.name }}
              </template>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="工程师确认时间"
            prop="confirm_at"
            align="right"
            width="132"
          ></el-table-column> -->
          <el-table-column label="服务状态" align="center" width="100">
            <template #default="{ row }">
              <template v-if="row.service_status === 0">待确认</template>
              <template v-if="row.service_status === 1">待签到</template>
              <template v-if="row.service_status === 2">服务中</template>
              <template v-if="row.service_status === 3">已搁置</template>
              <el-tag v-if="row.service_status === 4" type="success">服务完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="服务完成时间"
            prop="finish_at"
            align="right"
            width="132"
          ></el-table-column>
          <el-table-column label="订单状态" align="center" width="100">
            <template #default="{ row }">
              <template v-if="row.status === 0">待支付上门费</template>
              <template v-if="row.status === 1">待派遣</template>
              <template v-if="row.status === 2">服务中</template>
              <template v-if="row.status === 3">待支付服务费</template>
              <el-tag v-if="row.status === 4" type="success">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template #default="{ row }">
              <el-button
                type="text"
                :disabled="row.status !== 0 && row.status !== 1"
                @click="assign(row.id)"
              >指派工程师</el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column label="评论状态" align="center" width="100">
            <template #default="{ row }">
              <el-tag v-if="row.eval_status === 0" type="info">未评论</el-tag>
              <el-tag v-if="row.eval_status === 1" type="success">已评论</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="发票状态" align="center" width="100">
            <template #default="{ row }">
              <el-tag v-if="row.invoice_status === -1" type="danger">不可开</el-tag>
              <el-tag v-if="row.invoice_status === 1" type="warning">可开</el-tag>
              <el-tag v-if="row.invoice_status === 2" type="success">已开</el-tag>
            </template>
          </el-table-column> -->
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
    <Assign ref="assignDialog" @submit="getList"></Assign>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { usePagination } from '@tj/ui';
import request from '@/hooks/request';
import Assign from './Assign.vue';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/order/companyOrderList', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const assignDialog = ref();

function assign(orderId) {
  assignDialog.value.open(orderId);
}

onMounted(() => {
  getList();
});
</script>
